<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link
	href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
	rel="stylesheet"
/>
<link
	rel="stylesheet"
	type="text/css"
	href="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick.css"
/>
<link
	rel="stylesheet"
	type="text/css"
	href="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick-theme.css"
/>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer" />

<div class="home-page">
	<div id="darkModeToggleBtn">
		<span><i class="fa-regular fa-moon"></i></span>
	</div>
	<nav class="home-nav" [ngClass]="activeClass" id="nav">
		<div class="container">
			<div class="home-header">
				<div class="row">
					<div class="col-7">
						<ul class="nav-menu">
							<li class="menu-logo">
								<img
									class="logo-img"
									src="assets/img/logo.png"
									alt=""
								/><img
									class="dark-mode"
									src="assets/img/white-logo.png"
									alt=""
								/>
							</li>
							<!-- <li class="nav-menu-item">
								<a
									routerLink="/documentation-v2"
									routerLinkActive="active"
									>Developers</a
								>
							</li> -->
						</ul>
					</div>
					<div class="col-5">
						<div class="login-btn">
							<a href="#become_a_partner">Become a Partner</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nav>
	<section class="home-banner">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="banner-content">
						<h1>
							Instant Payment Solutions Customized to Meet Your Business Requirements
						</h1>
						<p>
							Optimize Transactions, Simplify Payouts, and Effectively Handle Your Financial Operations Online with Pay2local's Dynamic APIs and Merchant Dashboard.
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="advatages">
		<div class="container">
			<div class="head-content">
				<h3>Advantages We Offer</h3>
			</div>
			<div class="row">
				<div class="col-lg-4 col-sm-6">
					<div class="content-wrapper">
						<i class="fa-regular fa-thumbs-up"></i>
						<h3>Easy Payment Form</h3>
						<p>The payment form is extremely user-friendly, requiring minimal information.</p>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6">
					<div class="content-wrapper">
						<i class="fa-regular fa-hourglass"></i>
						<h3>24/7 Support & service</h3>
						<p>We operate 24/7 and promptly resolve any issues that may arise. Your satisfaction as a client is highly valued by us.</p>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6">
					<div class="content-wrapper">
						<i class="fa-solid fa-gears"></i>
						<h3>Effortless Integrate</h3>
						<p>It’s very effortless to integrate Pay2local.</p>
					</div>
				</div>
				
			</div>
		</div>
	</section>
	<!-- <section class="secure-payment">
		<div class="container">
			<div class="head">
				<h2>Ensuring Security and Regulatory Compliance</h2>
				<h3>Ensuring Secure Transactions for Your Peace of Mind</h3>
			</div>
		</div>
		<div class="container">
			<div class="content">
				<div class="row align-items-center">
					<div class="col-lg-4">
						<p class="content-left">
							Rest assured, every transaction is processed within
							a secure network, strictly adhering to PCI DSS
							standards, local requirements, and regulations,
							ensuring the utmost safety and compliance.
						</p>
					</div>
					<div class="col-lg-4">
						<div class="image-secure-payment">
							<img src="assets/img/secure-payment.png" alt="" />
						</div>
					</div>
					<div class="col-lg-4">
						<p class="content-right">
							Your payment data is meticulously encrypted and
							stored across multiple state-of-the-art data
							centers, ensuring redundancy and high availability,
							guaranteeing the utmost security and accessibility.
						</p>
					</div>
				</div>
			</div>
		</div>
	</section> -->

	<!-- <section class="crypto-deposit">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-6">
					<div class="content">
						<h2>
							Easy Deposits and Withdrawals with Our Payment
							Gateway
						</h2>
						<p>
							Elevate your digital finance experience with our
							Payment Gateway seamless crypto deposit and
							withdrawal functionality. Introducing hassle-free
							transactions with cryptocurrencies, making managing
							your digital assets effortless and secure.
						</p>
					</div>
				</div>
				<div class="col-md-6">
					<div class="image">
						<img src="assets/img/deposit-withdraw.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</section> -->

	<section class="payment-options">
		<div class="container">
			<div class="head">
				<h2>Payment Options</h2>
				<h3>Increase Your Revenue and Accelerate Business Growth</h3>
			</div>
		</div>
		<div class="container">
			<div class="content">
				<div class="sliderr row">
					<!-- <div class="col-md-6 col-4">
						<div class="payment-option">
							<img
								src="assets/img/icicibank.jpeg"
								alt=""
							/>
						</div>
					</div>
					<div class="col-md-6 col-4">
						<div class="payment-option">
							<img
								src="assets/img/mobile_banks.jpeg"
								alt=""
							/>
						</div>
					</div> -->
					<div class="col-md-6 col-4">
						<div class="payment-option">
							<img
								src="assets/img/upay.png"
								alt=""
							/>
						</div>
					</div>
					<div class="col-md-6 col-4">
						<div class="payment-option">
							<img
								src="assets/img/paytm.png"
								alt=""
							/>
						</div>
					</div>
					<div class="col-md-3 col-4">
						<div class="payment-option">
							<img
								src="assets/img/rocket.png"
								alt=""
							/>
						</div>
					</div>
					<div class="col-md-3 col-4">
						<div class="payment-option">
							<img
								src="assets/img/bkash.png"
								alt=""
							/>
						</div>
					</div>
					<!-- <div class="col-md-6 col-4">
						<div class="payment-option">
							<img
								src="assets/img/islami.jpeg"
								alt=""
							/>
						</div>
					</div>
					
					<div class="col-md-6 col-4">
						<div class="payment-option">
							<img
								src="assets/img/tcash.jpeg"
								alt=""
							/>
						</div>
					</div> -->
					<!-- <div class="col-md-3">
						<div class="payment-option">
							<img
								src="assets/img/wallet.jpeg"
								alt=""
							/>
						</div>
					</div> -->
					<div class="col-md-3 col-4">
						<div class="payment-option">
							<img
								src="assets/img/phonepe.png"
								alt=""
							/>
						</div>
					</div>
					<div class="col-md-3 col-4">
						<div class="payment-option">
							<img
								src="assets/img/gpay.png"
								alt=""
							/>
						</div>
					</div>
					<div class="col-md-6 col-4">
						<div class="payment-option">
							<img
								src="assets/img/nagad.png"
								alt=""
							/>
						</div>
					</div>
					<div class="col-md-6 col-4">
						<div class="payment-option">
							<img
								src="assets/img/bharat-pay.png"
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="carrier-section">
		<div class="container">
			<div class="row">
				<div class="col-lg-3 col-md-6">
					<div class="carrier">
						<h2><i class="fa-light fa-users"></i> 100+ </h2>
						<p>
							Showcase the widespread network of agents utilizing your payment gateway, indicating its popularity and adoption within the agent community.
						</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="carrier">
						<h2><i class="fa-solid fa-user-group"></i> 35+</h2>
						<p>
							Highlight the diverse range of merchants actively using your payment gateway, demonstrating its versatility and suitability for various business types.
						</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="carrier">
						<h2><i class="fa-solid fa-coins"></i> 2M+</h2>
						<p>Display the staggering volume of transactions successfully processed through your platform, underscoring its reliability and efficiency in handling large-scale operations.</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="carrier">
						<h2><i class="fa-solid fa-check-double"></i> 99%</h2>
						<p>Emphasize the consistently high success rate of transactions facilitated by your payment gateway, indicating its dependability and trustworthiness for both agents and merchants.</p>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- <section class="carrier-section">
		<div class="container">
			<div class="row">
				<div class="col-lg-3 col-md-6">
					<div class="carrier">
						<h2>2+</h2>
						<p>
							Years of Simplifying Access to Highly Sought-After
							Markets
						</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="carrier">
						<h2>50+</h2>
						<p>
							Partners in Business Growth: Clients Thriving with
							Fionpay
						</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="carrier">
						<h2>1+</h2>
						<p>Million Satisfied Customers</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="carrier">
						<h2>10+</h2>
						<p>
							Comprehensive Coverage Across Countries with
							Tailored Local Payment Solutions
						</p>
					</div>
				</div>
			</div>
		</div>
	</section> -->

	<section class="globel-coverage">
		<!-- <div class="container">
			<div class="head-content">
				<h2>Comprehensive Global Reach</h2>
				<h3>Boost Conversion Rates and Maximize Sales</h3>
				<p>
					Ensure Optimal Payment Success in Emerging Markets across
					Asia, Africa, and Latin America. Fionpay Integration
					Encompasses Key Payment Solutions in India, Bangladesh,
					Pakistan, Nepal, and Srilanka, Ensuring Seamless
					Transactions.
				</p>
			</div>
		</div> -->
		<div class="container">
			<div>
				<div class="row">
					<div class="col-md-12">
						<div class="head">
							<h2>Current Operating Countries</h2>
						</div>
					</div>
					<div class="col-md-12">
						<div class="primary-countries">
							<div class="row slider-twoo">
								<div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/bangladesh-flag.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>Bangladesh</h2>
										</div>
									</div>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/india-flag.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>India</h2>
										</div>
									</div>
								</div>
					
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div>
				<div class="row">
					<div class="col-md-12">
						<div class="head">
							<h2>Upcoming Countries</h2>
						</div>
					</div>
					<div class="col-md-12">
						<div class="Upcoming-Countries">
							<div class="row slider-threee">
								<!-- <div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/usa-flag.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>USA</h2>
										</div>
									</div>
								</div> -->
								
								
								
								<div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/pakistan-flag.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>Pakistan</h2>
										</div>
									</div>
								</div>
								
								
								<div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/nepal-flag.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>Nepal</h2>
										</div>
									</div>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/srilanka-flag.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>Srilanka</h2>
										</div>
									</div>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/Egypt-flag.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>Egypt</h2>
										</div>
									</div>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/Vietnam.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>Vietnam</h2>
										</div>
									</div>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/indonesia.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>Indonesia</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div>
				<div class="row">
					<div class="col-md-12">
						<div class="head">
							<h2>Upon Request Countries</h2>
						</div>
					</div>
					<div class="col-md-12">
						<div class="Request-Countries">
							<div class="row slider-twoo">
								<div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/African -flag.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>African Countries</h2>
										</div>
									</div>
								</div>
								<div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/Latin-American-countries.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>Latin American Countries</h2>
										</div>
									</div>
								</div>
					            <div class="col-xl-2 col-lg-3 col-md-4 col-6">
									<div class="country">
										<div class="country-img">
											<img
												src="assets/img/global-icon.png"
												alt=""
											/>
										</div>
										<div class="country-name">
											<h2>Others</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="form-section" id="become_a_partner">
		<div class="container-wrapper">
			<div class="container">
				<div class="row">
					<div class="col-md-6">
						<div class="contact-info">
							<h2>Connect With Us:</h2>
							<ul>
								<li>
									<h3>E-mail</h3>
									<p><a href="mailto:contact@Pay2local.com"><i class="fa-regular fa-envelope"></i> <span>{{'contact@Pay2local.com'}}</span></a></p>
								</li>
								<li>
									<h3>Telegram</h3>
									<p><a href="mailto:contact@Pay2local.com"><i class="fa-brands fa-telegram"></i> <span>{{'@Pay2local'}}</span></a></p>
								</li>
								<li>
									<h3>Skype</h3>
									<p><a href="mailto:contact@Pay2local.com"><i class="fa-brands fa-skype"></i> <span>{{'@Pay2local'}}</span></a></p>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-wrapper">
							<form action="">
								<div class="form-field">
									<label for="">Full Name</label>
									<input type="text">
								</div>
								<div class="form-field">
									<label for="">Email</label>
									<input type="text">
								</div>
								<div class="form-field">
									<label for="">Phone Number</label>
									<input type="text">
								</div>
								<div class="form-field">
									<label for="">Country</label>
									<input type="text">
								</div>
								<div class="form-field">
									<label for="">Message</label>
									<textarea name="" id="" cols="30" rows="10"></textarea>
								</div>
								<div class="form-btn-field">
									<button class="btn">Become a Partner</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<footer>
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-2">
					<div class="footer-logo">
						<img src="assets/img/white-logo.png" alt="" />
					</div>
				</div>
				<!-- <div class="col-md-2">
					<div class="footer-menu-item">
						<a routerLink="/documentation"
						routerLinkActive="active">Developers</a>
					</div>
				</div> -->
				<div class="col-md-2">
					<div class="footer-menu-item"><a href="">Policy</a></div>
				</div>
				<div class="col-md-2">
					<div class="footer-menu-item">
						<a href="">Term & Condition</a>
					</div>
				</div>
			</div>
		</div>
	</footer>
</div>
<script
	type="text/javascript"
	src="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick.min.js"
></script>
