import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  apiUrl: string = environment.backendHost;
  objectRefresh = new Subject<any>();
  currentBalanceRefresh = new Subject<any>();
  isChangeThemeMode = new Subject<any>();
  // isAutoRefreshOBJ = new Subject<any>;
  constructor(private http: HttpClient) { }

  getCurrentRole() {
    let userProfile: any = localStorage.getItem('userProfile');
    let userStorageObj = JSON.parse(userProfile);
    return userStorageObj.userRoles[0];
  }
  getAllBanks(data:any) {
    return this.http.post(
      `${this.apiUrl}/api/v1/mobile_bankings/custom_filter`,data
    );
  }
  /** BL Count Api Reftesh */
  countObjectRefresh(refresh: boolean) {
    this.objectRefresh.next({ refresh: refresh });
  }
  /** BL Count Api Reftesh */
  getcountObjectRefresh() {
    return this.objectRefresh.asObservable();
  }
  /** BL auto Refresh */
  isBLAutoRefresh(refresh: any) {
    this.objectRefresh.next({ refresh: refresh.refresh, pageNumber: refresh.pageNumber })
  }

  getIsBLAutoRefresh() {
    return this.objectRefresh.asObservable();
  }
  /** Current Balance Refresh */
  currentBalance(refresh: boolean) {
    this.currentBalanceRefresh.next({ refresh: refresh });
  }
  getCurrentBalance(){
    return this.currentBalanceRefresh.asObservable();
  }
  getDashboardData() {
    return this.http.get(
      `${this.apiUrl}api/v1/users/dashboard_data`
    );
  }

  getAllCompanies() {
    return this.http.get(
      `${this.apiUrl}api/v1/companies`
    );
  }

  getDistributors(company_id:string) {
    return this.http.get(
      `${this.apiUrl}api/v1/distributors?company_id=${company_id}`
    );
  }
  currentBalanceData(){
    return this.http.get(
      `${this.apiUrl}api/v1/users/current_balance`
    );
  }
  getPermissions(){
    return this.http.get(
      `${this.apiUrl}api/v1/users/current_permissions`
    );
  }

  paymentAllBanks(id:string) {
    return this.http.get(
      `${this.apiUrl}api/v1/users/check_payment?id=${id}`
    );
  }
  
  agentModem(payload:any) {
    return this.http.post(
      `${this.apiUrl}api/v1/users/agent_modem`,
      payload
    );
  }
  bankLimit(ID:any){
    return this.http.get(
      `${this.apiUrl}api/v1/secure/payment_requests/get_bank_limit?id=${ID}`
    );
  }
  customerPaymentRequest(data:any){
    return this.http.post(
      `${this.apiUrl}api/v1/secure/payment_requests/customer_request`,
      data
    );
  }
  customerWithdrawRequest(data:any){
    return this.http.post(
      `${this.apiUrl}api/v1/secure/payment_requests/customer_withdraw_request`,
      data
    );
  }
  relieseModem(phone_number:string){
    return this.http.put(
      `${this.apiUrl}api/v1/secure/payment_requests/reliese_modem`,
      {phone_number: phone_number}
    );
  }
  getParentPermissionOBJ(parentName:string){
    let permissionsData:any = localStorage.getItem('adminPermisson');
      permissionsData = JSON.parse(permissionsData);
      const parentPermissionObject = permissionsData?.modules.filter((item:any, index:any) => {
        return item.name == parentName;
       });
       return parentPermissionObject
  }
  getPermissionOBJ(parentName:string, childName:string){
      let permissionsData:any = localStorage.getItem('adminPermisson');
      console.log(permissionsData)
      permissionsData = JSON.parse(permissionsData);
      const parentPermissionObject = permissionsData?.modules.filter((item:any, index:any) => {
        return item.name == parentName;
       });
     
      let childPermission =  parentPermissionObject[0]?.permissions.filter((item:any, index:any) => {
        return item.name == childName;
       });
       return childPermission[0].permissions;
  }
  resetData(){
    return this.http.put(
      `${this.apiUrl}api/v1/users/reset_payment_data`,
      {}
    );
  }
  getModemAndMerchant(){
    return this.http.get(
      `${this.apiUrl}api/v1/payment_transactions/payments_filter_data`
    );
  } 
  getMerchantCurrentPermission(){
    return this.http.get(
      `${this.apiUrl}api/v1/merchant/dashboard/current_permission`
      );
    }
  /** Theme Mode change */
  setThemeMode(item:any){
    this.isChangeThemeMode.next(item)
  }
  getThemeMode(){
    return this.isChangeThemeMode.asObservable();
  }
}
