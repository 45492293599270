import { Injectable } from '@angular/core';
import { CommonService } from '@services/common/common.service';

@Injectable({
	providedIn: 'root',
})
export class GlobalRoutesService {
	private roles: any[] = [
		{
			name: 'Dashboard',
			url: `/admin/dashboard`,
			isEnabled: false,
			permissions: 'ALL',
			id: 'sidebar-nav',
			icon: 'bi bi-grid',
		},
		{
			name: 'Management',
			url: `#`,
			isEnabled: false,
			id: 'teams-nav',
			icon: 'bi bi-person',
			child: [
				{
					name: 'Teams',
					url: `/admin/teams`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Members',
			url: `#`,
			isEnabled: false,
			id: 'members-nav',
			icon: 'bi bi-person-lines-fill',
			child: [
				{
					name: 'Partners',
					url: `/admin/partners`,
					view: false,
					action: false,
				},
				{
					name: 'Distributors',
					url: `/admin/distributors`,
					view: false,
					action: false,
				},
				{
					name: 'Agents',
					url: `/admin/agents`,
					view: false,
					action: false,
				},
				{
					name: 'Modem List',
					url: `/admin/modem`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Balance Manager',
			url: `#`,
			isEnabled: false,
			id: 'balance-nav3',
			icon: 'bi bi-gem',
			child: [
				{
					name: 'All Transactions',
					url: `/admin/balance-manager`,
					view: false,
					action: false,
				},
				{
					name: 'Success',
					url: `/admin/balance-manager/success`,
					view: false,
					action: false,
				},
				{
					name: 'Pending',
					url: `/admin/balance-manager/pendings`,
					view: false,
					action: false,
				},
				{
					name: 'Rejected',
					url: `/admin/balance-manager/rejected`,
					view: false,
					action: false,
				},
				{
					name: 'Approved',
					url: `/admin/balance-manager/approved`,
					view: false,
					action: false,
				},
				{
					name: 'Danger',
					url: `/admin/balance-manager/danger`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Sms Inbox',
			url: `#`,
			isEnabled: false,
			id: 'sms-navs3',
			icon: 'bi bi-envelope',
			child: [
				{
					name: 'All sms',
					url: `/admin/sms-inboxs`,
					view: false,
					action: false,
				},
				{
					name: 'Cash Out',
					url: `/admin/sms-inboxs/cash-out`,
					view: false,
					action: false,
				},
				{
					name: 'Cash In',
					url: `/admin/sms-inboxs/cash-in`,
					view: false,
					action: false,
				},
				{
					name: 'B2B',
					url: `/admin/sms-inboxs/b2b`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Transactions',
			url: ``,
			isEnabled: false,
			id: 'transaction-navs3',
			icon: 'bi-cash-stack',
			child: [
				{
					name: 'Payments',
					url: `/admin/transactions/payments`,
					view: false,
					action: false,
				},
				{
					name: 'Commision Logs',
					url: `/admin/transactions/commision-logs`,
					view: false,
					action: false,
				},
				{
					name: 'Deposits',
					url: `/admin/transactions/deposits`,
					view: false,
					action: false,
				},
				{
					name: 'Withdrawals',
					url: `/admin/transactions/withdrawals`,
					view: false,
					action: false,
				},
				{
					name: 'Modem Balance Logs',
					url: `/admin/transactions/modem_balance_logs`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Pending Request',
			url: ``,
			isEnabled: false,
			id: 'pending-nav',
			icon: 'bi bi-layers-fill',
			child: [
				{
					name: 'Deposits',
					url: `/admin/deposits`,
					view: false,
					action: false,
				},
				{
					name: 'Withdrawals',
					url: `/admin/withdrawals`,
					view: false,
					action: false,
				},
				{
					name: 'Payments',
					url: `/admin/payments`,
					view: false,
					action: false,
				},
				{
					name: 'Others',
					url: `/admin/others`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Incomplete Requests',
			url: ``,
			isEnabled: false,
			id: 'incomplete-nav',
			icon: 'bi bi-layers-fill',
			child: [
				{
					name: 'Deposit Requests',
					url: `/admin/incomplete-deposit-request`,
					view: false,
					action: false,
				},
				{
					name: 'Withdrwal Requests',
					url: `/admin/incomplete-withdrawals-request`,
					view: false,
					action: false,
				},
				{
					name: 'Refund Requests',
					url: `/admin/incomplete-refund-request`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Payments',
			url: ``,
			isEnabled: false,
			id: 'payment-nav',
			icon: 'bi-cash-coin',
			child: [
				{
					name: 'Merchants Payments',
					url: `/admin/merchants-payments`,
					view: false,
					action: false,
				},
				{
					name: 'Member Payments',
					url: `/admin/member-payments`,
					view: false,
					action: false,
				},
				{
					name: 'Other Payments',
					url: `/admin/others-payments`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Merchants',
			url: ``,
			isEnabled: false,
			id: 'mechant-nav',
			icon: 'bi bi-people',
			child: [
				{
					name: 'Value',
					url: `/admin/value`,
					view: false,
					action: false,
				},
				{
					name: 'Only Deposits',
					url: `/admin/deposit-merchant`,
					view: false,
					action: false,
				},
				{
					name: 'Only Withdrawals',
					url: `/admin/withdrawal-merchant`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Reports',
			url: ``,
			isEnabled: false,
			id: 'report-nav',
			icon: 'bi bi-graph-up-arrow',
			child: [
				{
					name: 'Balance Reports',
					url: `/admin/balance-reports`,
					view: false,
					action: false,
				},
				{
					name: 'Transations Reports',
					url: `/admin/transactions-reports`,
					view: false,
					action: false,
				},
				{
					name: 'Payments Reports',
					url: `/admin/payments-reports`,
					view: false,
					action: false,
				},
				{
					name: 'Total Usages',
					url: `/admin/total-usages`,
					view: false,
					action: false,
				},
				{
					name: 'Profit and Loss',
					url: `/admin/profit-and-loss`,
					view: false,
					action: false,
				},
				{
					name: 'User Statistics',
					url: `/admin/user-statistics`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Logs',
			url: ``,
			isEnabled: false,
			id: 'log-nav',
			icon: 'bi bi-card-text',
			child: [
				{
					name: 'Merchant API Keys',
					url: `/admin/merchant-api-keys`,
					view: false,
					action: false,
				},
				{
					name: 'Active Users',
					url: `/admin/active-users`,
					view: false,
					action: false,
				},
				{
					name: 'Withdraw USSD Logs',
					url: `/admin/withdraw-ussd-logs`,
					view: false,
					action: false,
				},
				{
					name: 'Balance USSD Logs',
					url: `/admin/balance-ussd-logs`,
					view: false,
					action: false,
				},
				{
					name: 'Network USSD Logs',
					url: `/admin/network-ussd-logs`,
					view: false,
					action: false,
				},
				{
					name: 'Access Logs',
					url: `/admin/access-logs`,
					view: false,
					action: false,
				},
				{
					name: 'Failed Logs',
					url: `/admin/failed-logs`,
					view: false,
					action: false,
				},
				{
					name: 'Device Logs',
					url: `/admin/device-logs`,
					view: false,
					action: false,
				},
				{
					name: 'Ip Restrictions',
					url: `/admin/ip-restrictions`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Payment Methods',
			url: ``,
			isEnabled: false,
			id: 'payment-m-nav',
			icon: 'bi bi-currency-exchange',
			child: [
				{
					name: 'Mobile Banking',
					url: `/admin/mobile-banking`,
					view: false,
					action: false,
				},
				{
					name: 'Crypto',
					url: `/admin/crypto`,
					view: false,
					action: false,
				}
			],
		},
		{
			name: 'Settings',
			url: ``,
			isEnabled: false,
			id: 'setting-nav',
			icon: 'bi bi-gear',
			child: [
				{
					name: 'Brandings',
					url: `/admin/brandings`,
					view: false,
					action: false,
				},
				{
					name: 'Data Delete Management',
					url: `/admin/data-delete-management`,
					view: false,
					action: false,
				},
				{
					name: 'Security Settings',
					url: `/admin/security-settings`,
					view: false,
					action: false,
				},
				{
					name: 'Api Settings',
					url: `/admin/api-settings`,
					view: false,
					action: false,
				},
				{
					name: 'Global Settings',
					url: `/admin/global-settings`,
					view: false,
					action: false,
				},
				{
					name: 'Banner Settings',
					url: `/admin/banner-settings`,
					view: false,
					action: false,
				},
				{
					name: 'Others',
					url: `/admin/others-settings`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Service',
			url: ``,
			isEnabled: false,
			id: 'service-nav',
			icon: 'bi bi-wrench-adjustable-circle',
			child: [
				{
					name: 'Crypto Rate Conversion',
					url: `/admin/crypto-rate-conversion`,
					view: false,
					action: false,
				},
				{
					name: 'Banking Rate Conversion',
					url: `/admin/banking-rate-conversion`,
					view: false,
					action: false,
				},
				{
					name: 'Balance Limit',
					url: `/admin/balance-limit`,
					view: false,
					action: false,
				},
				{
					name: 'Banking Limit',
					url: `/admin/banking-limit`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Support',
			url: ``,
			isEnabled: true,
			id: 'support-nav',
			icon: 'bi bi-headphones',
			child: [
				{
					name: 'Support Ticket',
					url: `/admin/support-tickets`,
					view: true,
					action: true,
				},
			],
		},
	];
	private merchanRoles: any[] = [
		{
			name: 'Dashboard',
			url: ``,
			isEnabled: true,
			permissions: 'ALL',
			id: 'sidebar-nav',
			icon: 'bi bi-grid',
		},
		{
			name: 'Balance',
			url: ``,
			isEnabled: false,
			id: 'balance-nav',
			icon: 'bi bi-layers-fill',
			child: [
				{
					name: 'Settlement',
					url: `/merchant/settlement-balance`,
					view: false,
					action: false,
				},
				{
					name: 'Prepayment',
					url: `/merchant/prepayment-balance`,
					view: true,
					action: true,
				},
				{
					name: 'Settlement Report',
					url: `/merchant/settlement`,
					view: true,
					action: true,
				},
			],
		},
		{
			name: 'Pending Request',
			url: ``,
			isEnabled: false,
			id: 'pending-nav',
			icon: 'bi bi-layers-fill',
			child: [
				{
					name: 'Deposits',
					url: `/merchant/deposits`,
					view: false,
					action: false,
				},
				{
					name: 'Withdrawals',
					url: `/merchant/withdrawals`,
					view: false,
					action: false,
				},
				{
					name: 'Payments',
					url: `/merchant/payments`,
					view: true,
					action: true,
				},
			],
		},
		{
			name: 'Transactions',
			url: ``,
			isEnabled: false,
			id: 'transaction-nav',
			icon: 'bi-cash-stack',
			child: [
				{
					name: 'Deposits',
					url: `/merchant/transactions/deposits`,
					view: false,
					action: false,
				},
				{
					name: 'Withdrawals',
					url: `/merchant/transactions/withdrawals`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Reports',
			url: `#`,
			isEnabled: false,
			id: 'report-nav',
			icon: 'bi bi-graph-up-arrow',
			child: [
				{
					name: 'Balance Reports',
					url: `/merchant`,
					view: false,
					action: false,
				},
				{
					name: 'Payments Reports',
					url: `/merchant/reports/payments`,
					view: false,
					action: false,
				},
				{
					name: 'Withdrawals Reports',
					url: `/merchant/reports/withdrawals`,
					view: false,
					action: false,
				},
				{
					name: 'Deposits Reports',
					url: `/merchant/reports/deposits`,
					view: false,
					action: false,
				},
				{
					name: 'Transaction Reports',
					url: `/merchant/reports/deposits-and-withdrawals`,
					view: true,
					action: true,
				},
				{
					name: 'Total Usages',
					url: `/merchant`,
					view: false,
					action: false,
				},
				{
					name: 'Profit and Loss',
					url: `/merchant`,
					view: false,
					action: false,
				},
				{
					name: 'User Statistics',
					url: `/merchant`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Developer',
			url: ``,
			isEnabled: false,
			id: 'log-nav',
			icon: 'bi bi-card-text',
			child: [
				{
					name: 'Settings',
					url: `/merchant/developer-settings`,
					view: false,
					action: false,
				},
				{
					name: 'API DOC',
					url: `/merchant/api-document`,
					view: false,
					action: false,
				},
			],
		},
		{
			name: 'Support',
			url: ``,
			isEnabled: true,
			id: 'support-nav2',
			icon: 'bi bi-grid',
			child: [
				{
					name: 'Support Ticket',
					url: `/merchant/support-tickets`,
					view: true,
					action: true,
				},
			],
		},
	];
	private permissions: any[] = [
		{
			name: 'Dashboard',
			url: `/admin/dashboard`,
			showOption: true,
			permissions: [],
			id: 'sidebar-nav',
			icon: 'bi bi-grid',
		},
		{
			name: 'Management',
			url: `#`,
			showOption: false,
			id: 'teams-nav',
			icon: 'bi bi-person',
			permissions: [
				{
					name: 'Team',
					showOption: false,
					url: `/admin/teams`,
					permissions: [
						{
							name: 'Add Team',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View Team List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Team',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Sign In With Team',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Account Suspended',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Members',
			url: `#`,
			showOption: false,
			id: 'members-nav',
			icon: 'bi bi-person-lines-fill',
			permissions: [
				{
					name: 'Partners',
					showOption: false,
					url: `/admin/partners`,
					permissions: [
						{
							name: 'View Partner List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Partner',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Partner',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Change Password',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Account Suspended',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Sign In With Partner',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Hold Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash In Commission',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash Out Commission',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Distributors',
					showOption: false,
					url: `/admin/distributors`,
					permissions: [
						{
							name: 'View Distributor List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Distributor',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Distributor',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Change Password',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Account Suspended',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Sign In With Distributor',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Hold Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash In Commission',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash Out Commission',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Agents',
					showOption: false,
					url: `/admin/agents`,
					permissions: [
						{
							name: 'View Agents List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Agent',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Agent',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Change Password',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Account Suspended',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Sign In With Agents',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Hold Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash In Commission',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash Out Commission',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Modem List',
					showOption: false,
					url: `/admin/modem`,
					permissions: [
						{
							name: 'Modem View List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Modem',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Modem',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Active Modem',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'InActive Modem',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Delete Modem',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Hold Balance',
							showOption: false,
							isChecked: false,
						},
					],
				},{
					name: 'Member Support Tickets',
					showOption: false,
					url: '/admin/member-support-tickets',
					permissions: [
						{
							name: 'View List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Ticket',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Ticket',
							showOption: false,
							isChecked: false,
						},
					]
					}
			],
		},
		{
			name: 'Balance Manager',
			url: `#`,
			showOption: false,
			id: 'balance-nav',
			icon: 'bi bi-gem',
			permissions: [
				{
					name: 'All Transactions',
					showOption: false,
					url: `/admin/balance-manager`,
					permissions: [
						{
							name: 'View Transaction',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'rejected',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Success',
					showOption: false,
					url: `/admin/balance-manager/success`,
					permissions: [
						{
							name: 'View Success',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Pending',
					showOption: false,
					url: `/admin/balance-manager/pendings`,
					permissions: [
						{
							name: 'View Pendings',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'rejected',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Rejected',
					showOption: false,
					url: `/admin/balance-manager/rejected`,
					permissions: [
						{
							name: 'View Rejected',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Approved',
					showOption: false,
					url: `/admin/balance-manager/approved`,
					permissions: [
						{
							name: 'View Approved',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Danger',
					showOption: false,
					url: `/admin/balance-manager/danger`,
					permissions: [
						{
							name: 'View Danger',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'rejected',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Sms Inbox',
			url: `#`,
			showOption: false,
			id: 'sms-nav3',
			icon: 'bi bi-envelope',
			permissions: [
				{
					name: 'All SMS',
					showOption: false,
					url: `/admin/sms-inboxs`,
					permissions: [
						{
							name: 'All SMS',
							showOption: true,
							isChecked: false,
						},
					],
				},
				{
					name: 'Cash Out',
					showOption: false,
					url: `/admin/sms-inboxs/cash-out`,
					permissions: [
						{
							name: 'Cash Out',
							showOption: true,
							isChecked: false,
						},
					],
				},
				{
					name: 'Cash In',
					url: `/admin/sms-inboxs/cash-in`,
					showOption: false,
					permissions: [
						{
							name: 'Cash In',
							showOption: true,
							isChecked: false,
						},
					],
				},
				{
					name: 'B2B',
					showOption: false,
					url: `/admin/sms-inboxs/b2b`,
					permissions: [
						{
							name: 'B2B',
							showOption: true,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Balance',
			url: ``,
			showOption: false,
			id: 'balance-nav3',
			icon: 'bi-cash-stack',
			permissions: [
				{
					name: 'Prepayment',
					url: `/admin/transactions/deposits`,
					showOption: false,
					permissions: [
						{
							name: 'View List',
							showOption: false,
							isChecked: false,
						}
					],
				},{
					name: 'Add Balance Request',
					url: `/admin/transactions/deposits`,
					showOption: false,
					permissions: [
						{
							name: 'View List',
							showOption: false,
							isChecked: false,
						}
					],
				},
				{
					name: 'Statelement Report Merchant',
					url: `/admin/transactions/deposits`,
					showOption: false,
					permissions: [
						{
							name: 'View List',
							showOption: false,
							isChecked: false,
						}
					],
				}
			],
		},
		{
			name: 'Transactions',
			url: ``,
			showOption: false,
			id: 'transaction-nav3',
			icon: 'bi-cash-stack',
			permissions: [
				{
					name: 'Deposits',
					url: `/admin/transactions/deposits`,
					showOption: false,
					permissions: [
						{
							name: 'View deposits List',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Commision Logs',
					showOption: false,
					url: `/admin/transactions/commision-logs`,
					permissions: [
						{
							name: 'View Commision List',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Withdrawals',
					url: `/admin/transactions/withdrawals`,
					showOption: false,
					permissions: [
						{
							name: 'View Withdrawals List',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Modem Balance Logs',
					url: `/admin/transactions/modem_balance_logs`,
					showOption: false,
					permissions: [
						{
							name: 'View Withdrawals List',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Payments',
					showOption: false,
					url: `/admin/transactions/payments`,
					permissions: [
						{
							name: 'View Payment List',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Pending Request',
			url: ``,
			isEnabled: false,
			id: 'pending-nav',
			icon: 'bi bi-layers-fill',
			permissions: [
				{
					name: 'Deposits',
					url: `/admin/deposits`,
					showOption: false,
					permissions: [
						{
							name: 'View deposits List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Reject Request',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Withdrawals',
					url: `/admin/withdrawals`,
					showOption: false,
					permissions: [
						{
							name: 'View Withdrawals List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Reject Request',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Payments',
					url: `/admin/payments`,
					showOption: false,
					permissions: [
						{
							name: 'View Payment List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Reject Request',
							showOption: false,
							isChecked: false,
						},
						
								{
									name: 'Add',
									showOption: false,
									isChecked: false,
								},
								{
									name: 'Return',
									showOption: false,
									isChecked: false,
								}
					],
				},
				{
					name: 'Others',
					url: `/admin/others`,
					showOption: false,
					permissions: [
						{
							name: 'View Others List',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Incomplete Requests',
			url: ``,
			isEnabled: false,
			id: 'incomplete-nav',
			icon: 'bi bi-layers-fill',
			permissions: [
				{
					name: 'Deposits',
					url: `/admin/incomplete-deposit-request`,
					showOption: false,
					permissions: [
						{
							name: 'View deposits List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Reject Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Return',
							showOption: false,
							isChecked: false,
						}
						
					],
				},
				{
					name: 'Withdrawals',
					url: `/admin/incomplete-withdrawals-request`,
					showOption: false,
					permissions: [
						{
							name: 'View Withdrawals List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Reject Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Return',
							showOption: false,
							isChecked: false,
						}
					],
				},
				{
					name: 'Refunds',
					showOption: false,
					url: `/admin/incomplete-refund-request`,
					permissions: [
						{
							name: 'View Refunds List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Reject Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Return',
							showOption: false,
							isChecked: false,
						}
					],
				},
			],
		},
		{
			name: 'Payments',
			url: ``,
			isEnabled: false,
			id: 'payment-nav',
			icon: 'bi-cash-coin',
			permissions: [
				{
					name: 'Merchants Payments',
					showOption: false,
					url: `/admin/merchants-payments`,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Member Payments',
					url: `/admin/member-payments`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Other Payments',
					url: `/admin/others-payments`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Merchants',
			url: ``,
			isEnabled: false,
			id: 'mechant-nav',
			icon: 'bi bi-people',
			permissions: [
				{
					name: 'Value',
					url: `/admin/value`,
					permissions: [
						{
							name: 'View Value Merchant List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Value Merchant',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Value Merchant',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Change Password',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Account Suspended',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Sign In With Value',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Hold Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash In Commission',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash Out Commission',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'API Keys',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Allow Nagitive Balance',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Only Deposits',
					url: `/admin/deposit-merchant`,
					showOption: false,
					permissions: [
						{
							name: 'View Deposit Merchants List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Deposit Merchants',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Deposit Merchants',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Change Password',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Account Suspended',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Sign In With Deposits',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Hold Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash-In Commission',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash-Out Commission',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'API Keys',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Allow Nagitive Balance',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Only Withdrawals',
					url: `/admin/withdrawal-merchant`,
					showOption: false,
					permissions: [
						{
							name: 'View Withdrawals Merchants List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Withdrawals Merchants',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Withdrawals Merchants',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Change Password',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Account Suspended',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Sign In With Withdrawals',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Hold Balance',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash-In Commission',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Cash-Out Commission',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'API Keys',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Allow Nagitive Balance',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Reports',
			url: ``,
			isEnabled: false,
			id: 'report-nav',
			icon: 'bi bi-graph-up-arrow',
			permissions: [
				{
					name: 'Balance Reports',
					url: `/admin/balance-reports`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Transations Reports',
					url: `/admin/transactions-reports`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Payments Reports',
					url: `/admin/payments-reports`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Total Usages',
					url: `/admin/total-usages`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Profit and Loss',
					url: `/admin/profit-and-loss`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'User Statistics',
					url: `/admin/user-statistics`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Logs',
			url: ``,
			isEnabled: false,
			id: 'log-nav',
			icon: 'bi bi-card-text',
			permissions: [
				{
					name: 'Merchant API Keys',
					url: `/admin/merchant-api-keys`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Active Users',
					url: `/admin/active-users`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Access Logs',
					url: `/admin/access-logs`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Failed Logs',
					url: `/admin/failed-logs`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Device Logs',
					url: `/admin/device-logs`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Ip Restrictions',
					url: `/admin/ip-restrictions`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Payment Methods',
			url: ``,
			isEnabled: false,
			id: 'payment-m-nav',
			icon: 'bi bi-currency-exchange',
			permissions: [
				{
					name: 'Mobile Banking',
					url: `/admin/mobile-banking`,
					showOption: false,
					permissions: [
						{
							name: 'List of bank',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit bank',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Active Bank',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Modem Networks Settings ',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add Modem Networks Settings',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Modem Networks Settings Change Status',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Modem Networks Settings',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Bank USSD Setting',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit Bank USSD Setting',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Bank USSD Setting Change Status',
							showOption: false,
							isChecked: false,
						}
					],
				},
				{
					name: 'Crypto',
					url: `/admin/crypto`,
					showOption: false,
					permissions: [
						{
							name: 'List of crypto',
							showOption: false,
							isChecked: false,
						},{
							name: 'Edit',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Add',
							showOption: false,
							isChecked: false,
						}
					],
				},
			],
		},
		{
			name: 'Settings',
			url: ``,
			isEnabled: false,
			id: 'setting-nav',
			icon: 'bi bi-gear',
			permissions: [
				{
					name: 'Brandings',
					url: `/admin/brandings`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Data Delete Management',
					url: `/admin/data-delete-management`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Security Settings',
					url: `/admin/security-settings`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Api Settings',
					url: `/admin/api-settings`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Global Settings',
					url: `/admin/global-settings`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Banner Settings',
					url: `/admin/banner-settings`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Others',
					url: `/admin/others-settings`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Service',
			url: ``,
			isEnabled: false,
			id: 'service-nav',
			icon: 'bi bi-wrench-adjustable-circle',
			permissions: [
				{
					name: 'Crypto Rate Conversion',
					url: `/admin/crypto-rate-conversion`,
					showOption: false,
					permissions: [
						{
							name: 'List of crypto',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Logs',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Banking Rate Conversion',
					url: `/admin/banking-rate-conversion`,
					showOption: false,
					permissions: [
						{
							name: 'List of Banking',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Logs',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Balance Limit',
					url: `/admin/balance-limit`,
					showOption: false,
					permissions: [
						{
							name: 'Show',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Update',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Banking Limit',
					url: `/admin/banking-limit`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Edit',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Update',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},{
            name: 'Support',
            showOption: false,
			id: 'support-nav',
			icon: 'bi bi-headphones',
            permissions: [
                {
                  name: 'Support Ticket',
				  url: `/admin/support-tickets`,
                  showOption: false,
                  permissions: [
                    {
                      name: 'Incomplete Deposit Request',
                      showOption: false,
                      isChecked: false,
                    },
                    {
                      name: 'Incomplete Withdrawals Request',
                      showOption: false,
                      isChecked: false,
                    },
                    {
                      name: 'Incomplete Refunds Request',
                      showOption: false,
                      isChecked: false,
                    },
                    {
                      name: 'Simple Query',
                      showOption: false,
                      isChecked: false,
                    }
                  ]
                }
              ]
            
            
          },
		// {
        //     name: 'Developer',
        //     url: ``,
        //     showOption: false,
        //     id: 'log-nav',
        //     icon: 'bi bi-card-text',
        //     permissions: [
        //       {
        //         name: 'Settings',
        //         url: `/admin/developer-settings`,
		// 		showOption: false,
        //         permissions: [
        //         {
        //           name: 'List',
        //           showOption: false,
        //           isChecked: false,}
        //         ]
        //       },
        //       {
        //         name: 'API DOC',
        //         url: `/admin/api-document`,
		// 		showOption:false,
        //         permissions: [
        //           {
        //             name: 'List',
        //             showOption: false,
        //             isChecked: false,}
        //           ]
        //       },
        //     ],
        //   }
	];
	private newMerchanRoles: any[] = [
		{
			name: 'Dashboard',
			url: `/merchant/dashboard`,
			showOption: true,
			permissions: [],
			id: 'sidebar-nav',
			icon: 'bi bi-grid',
		},
		
		{
			name: 'Balance',
			url: ``,
			showOption: false,
			id: 'balance-nav2',
			icon: 'bi-cash-stack',
			permissions: [
				{
					name: 'Prepayment',
					url: `/merchant/prepayment-balance`,
					showOption: false,
					permissions: [
						{
							name: 'View List',
							showOption: false,
							isChecked: false,
						}
					],
				},{
					name: 'Add Balance Request',
					url: `/merchant/add-balance-request`,
					showOption: false,
					permissions: [
						{
							name: 'View List',
							showOption: false,
							isChecked: false,
						}
					],
				},
				{
					name: 'Statelement Report Merchant',
					url: `/merchant/settlement`,
					showOption: false,
					permissions: [
						{
							name: 'View List',
							showOption: false,
							isChecked: false,
						}
					],
				}
			],
		},
		{
			name: 'Transactions',
			url: ``,
			showOption: false,
			id: 'transaction-nav',
			icon: 'bi-cash-stack',
			permissions: [
				{
					name: 'Deposits',
					url: `/merchant/transactions/deposits`,
					showOption: false,
					permissions: [
						{
							name: 'View deposits List',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Commision Logs',
					showOption: false,
					url: ``,
					permissions: [
						{
							name: 'View Commision List',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Withdrawals',
					url: `/merchant/transactions/withdrawals`,
					showOption: false,
					permissions: [
						{
							name: 'View Withdrawals List',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Modem Balance Logs',
					url: ``,
					showOption: false,
					permissions: [
						{
							name: 'View Withdrawals List',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Payments',
					showOption: false,
					url: ``,
					permissions: [
						{
							name: 'View Payment List',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Pending Request',
			url: ``,
			showOption: false,
			id: 'pending-nav',
			icon: 'bi bi-layers-fill',
			permissions: [
				{
					name: 'Deposits',
					url: `/merchant/deposits`,
					showOption: false,
					permissions: [
						{
							name: 'View deposits List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Reject Request',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Withdrawals',
					url: `/merchant/withdrawals`,
					showOption: false,
					permissions: [
						{
							name: 'View Withdrawals List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Reject Request',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Payments',
					url: `/merchant/payments`,
					showOption: false,
					permissions: [
						{
							name: 'View Payment List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Approved Request',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'Reject Request',
							showOption: false,
							isChecked: false,
						},
						
								{
									name: 'Add',
									showOption: false,
									isChecked: false,
								},
								{
									name: 'Return',
									showOption: false,
									isChecked: false,
								}
					],
				},
				{
					name: 'Others',
					url: ``,
					showOption: false,
					permissions: [
						{
							name: 'View Others List',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
		{
			name: 'Incomplete Requests',
			url: ``,
			showOption: false,
			id: 'incomplete-nav',
			icon: 'bi bi-layers-fill',
			permissions: [
				{
					name: 'Deposits',
					url: `/merchant`,
					showOption: false,
					permissions: [
						{
							name: 'View deposits List',
							showOption: false,
							isChecked: false,
						}
						
					],
				},
				{
					name: 'Withdrawals',
					url: `/merchant`,
					showOption: false,
					permissions: [
						{
							name: 'View Withdrawals List',
							showOption: false,
							isChecked: false,
						}
					],
				},
				{
					name: 'Refunds',
					showOption: false,
					url: `/merchant/incomplete-request/refund-requests`,
					permissions: [
						{
							name: 'View Refunds List',
							showOption: false,
							isChecked: false,
						}
					],
				},
			],
		},
		{
			name: 'Reports',
			url: ``,
			showOption: false,
			id: 'report-nav',
			icon: 'bi bi-graph-up-arrow',
			permissions: [
				{
					name: 'Balance Reports',
					url: `/merchant`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Transations Reports',
					url: `/merchant/reports/deposits-and-withdrawals`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Withdrawals Reports',
					url: `/merchant/reports/withdrawals`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Deposits Reports',
					url: `/merchant/reports/deposits`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Payments Reports',
					url: `/merchant/reports/payments`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Total Usages',
					url: `/merchant`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'Profit and Loss',
					url: `/merchant`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
				{
					name: 'User Statistics',
					url: `/merchant`,
					showOption: false,
					permissions: [
						{
							name: 'List',
							showOption: false,
							isChecked: false,
						},
						{
							name: 'View',
							showOption: false,
							isChecked: false,
						},
					],
				},
			],
		},
	{
            name: 'Support',
            showOption: false,
			id: 'support-nav2',
			icon: 'bi bi-headphones',
            permissions: [
                {
                  name: 'Support Ticket',
				  url: `/merchant/support-tickets`,
                  showOption: false,
                  permissions: [
                    {
                      name: 'Incomplete Deposit Request',
                      showOption: false,
                      isChecked: false,
                    },
                    {
                      name: 'Incomplete Withdrawals Request',
                      showOption: false,
                      isChecked: false,
                    },
                    {
                      name: 'Incomplete Refunds Request',
                      showOption: false,
                      isChecked: false,
                    },
                    {
                      name: 'Simple Query',
                      showOption: false,
                      isChecked: false,
                    }
                  ]
                }
              ]
            
            
          },
		
		{
			name: 'Developer',
			url: ``,
			showOption: false,
			id: 'log-nav2',
			icon: 'bi bi-card-text',
			permissions: [
				{
					name: 'Settings',
					url: `/merchant/developer-settings`,
					showOption: false,
					permissions: [{
						name: 'List',
						showOption: false,
						isChecked: false,
					}]
					
				},
				{
					name: 'API DOC',
					url: `/merchant/api-document`,
					showOption: false,
					permissions: [{
						name: 'List',
						showOption: false,
						isChecked: false,
					}]
				},
			],
		}
	];
	
	
	constructor(private commonService: CommonService) {}

	checkRoleNewPermission() {
		
		let permissionRoles: any = localStorage.getItem('adminPermisson');
		permissionRoles = JSON.parse(permissionRoles);
		permissionRoles?.modules.forEach((item: any) => {
			let findRole = this.permissions?.find(
				(t) => t?.name === item?.name
			);
			if (item.showOption) {
				findRole.showOption = item.showOption;
			}
			if (item?.name == findRole?.name && item?.name != 'Dashboard') {
				item?.permissions.forEach((roleItem: any) => {
					let findPermission = findRole?.permissions?.find(
						(p: any) => p?.name === roleItem?.name
					);
					if (roleItem?.showOption) {
						findPermission.showOption = roleItem?.showOption;
						findPermission.permissions = roleItem.permissions;
					}
				});
			} else {
				if (item?.showOption) {
					findRole.showOption = item?.showOption;
				}
			}
		});
		return this.permissions;
	}
	checkRolePermission() {
		let roles: any = localStorage.getItem('adminPermisson');
		roles = JSON.parse(roles);
		roles?.modules.forEach((item: any) => {
			let findRole = this.roles?.find((t) => t?.name === item?.name);
			findRole.isEnabled = false;
			if (item?.name == findRole?.name && item?.name != 'Dashboard') {
				item?.permissions.forEach((roleItem: any) => {
					let findPermission = findRole?.child?.find(
						(p: any) => p?.name === roleItem?.name
					);
					findPermission.view = roleItem?.view;
					findPermission.action = roleItem?.action;
					if (roleItem?.view || roleItem?.action) {
						findRole.isEnabled = false;
					}
				});
			} else {
				if (item?.isEnabled) {
					findRole.isEnabled = item?.isEnabled;
				}
			}
		});
		return this.roles;
	}
	checkMerchantRolePermission() {
		let merchantRoles: any = localStorage.getItem('merchantPermission');
		merchantRoles = JSON.parse(merchantRoles);
		merchantRoles?.permissions?.modules.forEach((item: any) => {
			let findRole = this.merchanRoles?.find(
				(t) => t?.name === item?.name
			);
			findRole.isEnabled = false;
			if (
				item?.name == findRole?.name &&
				item?.name != 'Dashboard' &&
				item?.name != 'Transactions Logs'
			) {
				item?.permissions.forEach((roleItem: any) => {
					let findPermission = findRole?.child?.find(
						(p: any) => p?.name === roleItem?.name
					);
					findPermission.view = roleItem?.view;
					findPermission.action = roleItem?.action;
					if (roleItem?.view || roleItem?.action) {
						findRole.isEnabled = false;
					}
				});
			} else {
				if (item?.isEnabled) {
					findRole.isEnabled = item?.isEnabled;
				}
			}
		});
		return this.merchanRoles;
	}
	checkNewMerchantRolePermission(){
		// let  newPermissionJSON: any = {modules: [

		// 	{
		// 		name: "Transactions",
		// 		showOption: true,
		// 		permissions: [
		// 			{
		// 				name: 'Deposits',
		// 				showOption: true,
		// 				permissions: [
		// 					{
		// 						name: 'View deposits List',
		// 						showOption: false,
		// 						isChecked: true,
		// 					}
		// 				]
		// 			}, {
		// 				name: 'Withdrawals',
		// 				showOption: true,
		// 				permissions: [
		// 					{
		// 						name: 'View Withdrawals List',
		// 						showOption: false,
		// 						isChecked: true,
		// 					}
		// 				]
		// 			}
		// 		],
	
		// 	},
		// 	{
		// 		name: 'Balance',
		// 		showOption: true,
		// 		permissions: [
		// 		  {
		// 			name: 'Prepayment',
		// 			showOption: true,
		// 			permissions: [
		// 			  {
		// 				name: 'View List',
		// 				showOption: false,
		// 				isChecked: true,
		// 			  }
		// 			],
		// 		  },{
		// 			name: 'Add Balance Request',
		// 			showOption: true,
		// 			permissions: [
		// 			  {
		// 				name: 'View List',
		// 				showOption: false,
		// 				isChecked: true,
		// 			  }
		// 			],
		// 		  },
		// 		  {
		// 			name: 'Statelement Report Merchant',
		// 			showOption: true,
		// 			permissions: [
		// 			  {
		// 				name: 'View List',
		// 				showOption: false,
		// 				isChecked: true,
		// 			  }
		// 			],
		// 		  }
		// 		]
		// 	  },
		// 	{
		// 		name: "Pending Request",
		// 		showOption: true,
		// 		permissions: [
		// 			{
		// 				name: 'Deposits',
		// 				showOption: true,
		// 				permissions: [
		// 					{
		// 						name: 'View deposits List',
		// 						showOption: false,
		// 						isChecked: true,
		// 					},
		// 					{
		// 						name: 'Approved Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Reject Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}
		// 				]
		// 			}, {
		// 				name: 'Withdrawals',
		// 				showOption: true,
		// 				permissions: [
		// 					{
		// 						name: 'View Withdrawals List',
		// 						showOption: false,
		// 						isChecked: true,
		// 					},
		// 					{
		// 						name: 'Approved Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Reject Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}
		// 				]
		// 			}, {
		// 				name: 'Payments',
		// 				showOption: true,
		// 				permissions: [
		// 					{
		// 						name: 'View Payment List',
		// 						showOption: false,
		// 						isChecked: true,
		// 					},
		// 					{
		// 						name: 'Approved Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Reject Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Add',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Return',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}
		// 				]
		// 			}
	
	
		// 		]
		// 	},
		// 	{
		// 		name: "Incomplete Requests",
		// 		showOption: true,
		// 		permissions: [
		// 			{
		// 				name: 'Deposits',
		// 				showOption: true,
		// 				permissions: [
		// 					{
		// 						name: 'View deposits List',
		// 						showOption: true,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Approved Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Reject Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Add',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Return',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}
		// 				]
		// 			}, {
		// 				name: 'Withdrawals',
		// 				showOption: false,
		// 				permissions: [
		// 					{
		// 						name: 'View Withdrawals List',
		// 						showOption: true,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Approved Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Reject Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Add',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Return',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}
		// 				]
		// 			}, {
		// 				name: 'Refunds',
		// 				showOption: false,
		// 				permissions: [
		// 					{
		// 						name: 'View Refunds List',
		// 						showOption: true,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Approved Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Reject Request',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Add',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 					{
		// 						name: 'Return',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}
		// 				]
		// 			}
	
		// 		]
		// 	},
			
		// 	{
		// 		name: 'Reports',
		// 		showOption: true,
		// 		permissions: [
		// 			{
		// 				name: 'Balance Reports',
		// 				showOption: false,
		// 				permissions: [
		// 					{
		// 						name: 'List',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}, {
		// 						name: 'View',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 				]
		// 			}, {
		// 				name: 'Transations Reports',
		// 				showOption: true,
		// 				permissions: [
		// 					{
		// 						name: 'List',
		// 						showOption: false,
		// 						isChecked: true,
		// 					}, {
		// 						name: 'View',
		// 						showOption: false,
		// 						isChecked: true,
		// 					},
		// 				]
		// 			},
		// 			{
		// 				name: 'Payments Reports',
		// 				showOption: false,
		// 				permissions: [
		// 					{
		// 						name: 'List',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}, {
		// 						name: 'View',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 				]
		// 			},
		// 			{
		// 				name: 'Total Usages',
		// 				showOption: false,
		// 				permissions: [
		// 					{
		// 						name: 'List',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}, {
		// 						name: 'View',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 				]
		// 			},
		// 			{
		// 				name: 'Profit and Loss',
		// 				showOption: false,
		// 				permissions: [
		// 					{
		// 						name: 'List',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}, {
		// 						name: 'View',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 				]
		// 			},
		// 			{
		// 				name: 'User Statistics',
		// 				showOption: false,
		// 				permissions: [
		// 					{
		// 						name: 'List',
		// 						showOption: false,
		// 						isChecked: false,
		// 					}, {
		// 						name: 'View',
		// 						showOption: false,
		// 						isChecked: false,
		// 					},
		// 				]
		// 			},
		// 		]
		// 	},
		// 	{
		// 		name: 'Support',
		// 		showOption: true,
		// 		permissions: [
		// 				{
		// 					name: 'Support Ticket',
		// 					showOption: true,
		// 					permissions: [
		// 						{
		// 							name: 'Incomplete Deposit Request',
		// 							showOption: false,
		// 							isChecked: true,
		// 						},
		// 						{
		// 							name: 'Incomplete Withdrawals Request',
		// 							showOption: false,
		// 							isChecked: true,
		// 						},
		// 						{
		// 							name: 'Incomplete Refunds Request',
		// 							showOption: false,
		// 							isChecked: true,
		// 						},
		// 						{
		// 							name: 'Simple Query',
		// 							showOption: false,
		// 							isChecked: true,
		// 						}
		// 					]
		// 				}
		// 			]
				
				
		// 	},{
		// 		name: 'Developer',
		// 		showOption: true,
		// 		permissions: [
		// 		  {
		// 			name: 'Settings',
		// 			showOption: true,
		// 			permissions: [
		// 			{
		// 			  name: 'List',
		// 			  showOption: false,
		// 			  isChecked: true,}
		// 			]
		// 		  },
		// 		  {
		// 			name: 'API DOC',
		// 			showOption: true,
		// 			permissions: [
		// 			  {
		// 				name: 'List',
		// 				showOption: false,
		// 				isChecked: true,}
		// 			  ]
		// 		  },
		// 		]
		// 	}
	
		// ]}
		let newPermissionJSON: any = localStorage.getItem('merchantPermission');
		newPermissionJSON = JSON.parse(newPermissionJSON);
		newPermissionJSON?.forEach((item: any) => {
			let findRole = this.newMerchanRoles?.find(
				(t) => t?.name === item?.name
			);
			if (item.showOption) {
				findRole.showOption = item.showOption;
			}
			if (item?.name == findRole?.name && item?.name != 'Dashboard') {
				item?.permissions.forEach((roleItem: any) => {
					let findPermission = findRole?.permissions?.find(
						(p: any) => p?.name === roleItem?.name
					);
					if (roleItem?.showOption) {
						findPermission.showOption = roleItem?.showOption;
						findPermission.permissions = roleItem.permissions;
					}
				});
			} else {
				if (item?.showOption) {
					findRole.showOption = item?.showOption;
				}
			}
		});
		return this.newMerchanRoles;
	}
}
