import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { GlobalRoutesService } from '@services/globalRoutes/global-routes.service';
import { CommonService } from '@services/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private commonService: CommonService, private authService: AuthService,private globalRoutes: GlobalRoutesService) {
   
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     let expectedRoles:any = state?.url;
    let routeName = expectedRoles.split("/");
    console.log(expectedRoles)
    if(routeName[1] == "admin"){
       this.getCurrentPermissions();
    }else if(routeName[1] == "merchant"){
      this.getMerchantCurrentPermission()
    }
      return new Promise<boolean>((resolve) => {
        setTimeout(() => {
            resolve(true);
            this.isAtuhorized(route,state);
        }, 1000); // 2000 milliseconds (2 seconds) delay before allowing/denying access
      });
  }
  private isAtuhorized(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {

    let matchValue: any;
    let routeName;
    let editRouteName
    let expectedRoles:any = state?.url;
    routeName = expectedRoles.split("/");
    if(routeName[3] == "edit" || routeName[3] == "add"){
      expectedRoles = "/"+routeName[1]+"/"+routeName[2];
    }else{
      expectedRoles = expectedRoles;
    }
     if(routeName[1] == "admin"){
      if (this.authService.isLoggedIn()) {
        //let roles: any = this.globalRoutes.checkRolePermission();
        let roles: any = this.globalRoutes.checkRoleNewPermission();
        roles.forEach((item:any) => {
          if(item.name == "Dashboard"){
            if(item.url == expectedRoles){
              matchValue = item?.showOchption;
            }
          }else{
            item?.permissions.forEach((childItem:any) => {
              if(expectedRoles==childItem.url){
                if(childItem.showOption){
                  matchValue = true
                }else{
                  matchValue = false;
                }
                // if(childItem.view || childItem.action){
                //   matchValue = true;
                // }else{
                //   matchValue = false;
                // }
                
              }
             });
          }
        });
      } else {
        window.location.href = "/admin/login";
        return false;
      }
     }else if(routeName[1] == "merchant"){
      if (this.authService.isLoggdInMerchant()) {
        let roles: any = this.globalRoutes.checkNewMerchantRolePermission();
        roles.forEach((item:any) => {
          if(item.name == "Dashboard" || item.name == "Transactions Logs"){
            
            if(item.url == expectedRoles){
              matchValue = item?.showOption;
            }
          }else{
            item?.permissions.forEach((childItem:any) => {
              if(expectedRoles==childItem.url){
                if(childItem.showOption){
                  matchValue = true
                }else{
                  matchValue = false;
                }
                // if(childItem.view || childItem.action){
                //   matchValue = true;
                // }else{
                //   matchValue = false;
                // }
                
              }
             });
          }
        });
        if(matchValue == false){
          window.location.href = "/merchant/dashboard"
         }
      } else {
        window.location.href = "/merchant/login";
        return false;
      }
     }
      
      if(matchValue == false){
       window.location.href = "/admin/dashboard"
      }
      //return matchValue;
      return true
  }
  getCurrentPermissions(){
		this.commonService.getPermissions().subscribe((res:any)=>{
      console.log(res)
			localStorage.setItem('adminPermisson',JSON.stringify(res.data))
		})
	}
  getMerchantCurrentPermission(){
    this.commonService.getMerchantCurrentPermission().subscribe((res:any)=>{
      console.log(res)
			localStorage.setItem('merchantPermission',JSON.stringify(res.data))
		})
  }
}
